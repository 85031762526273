import { GradingStation } from '@core/models/grading-station.model';

export class SetRejectedGradingStation {
  public static readonly type = '[Reject Harvest] Set selected grading station';

  constructor(public gradingStation: GradingStation) {}
}

export class SetRejectedReasonsAndStems {
  public static readonly type = '[Reject Harvest] Set rejected reasons and stems';

  constructor(
    public reasonId: string,
    public amount: number,
  ) {}
}

export class ResetRejectedReasonsAndStems {
  public static readonly type = '[Reject Harvest] Reset rejected reasons and stems';

  constructor(public reasonId: string) {}
}

export class ResetRejectedHarvest {
  public static readonly type = '[Reject Harvest] Reset Rejected Harvest';
}
